"use strict";

(function() {  //NOSONAR
	const SCROLL_ALL_CLASSES_SELECTOR = '.hover-scroll-overlay-y, .scroll-x, .scroll-y';
	const SHOW_SCROLL_CLASS = 'hover-scroll-overlay-y_show-scroll';
	const SCROLL_ELEMENTS_SELECTOR = '.hover-scroll-overlay-y';
	const SCROLL_CLASS_MAC_LIKE = 'scroll_mac-like';
	const SCROLL_CLASS_FIREFOX = 'scroll_firefox';
	const SCROLL_CLASS_CHROME = 'scroll_chrome';
	const SCROLL_CLASS_OTHER = 'scroll_other';

	class BrowserDetect {

		/**
		 * @returns {boolean}
		 */
		isMacLike() {
			return /(?<tmp>Mac|iPhone|iPod|iPad)/i.test(navigator.platform)  //NOSONAR
		}

		/**
		 * @returns {boolean}
		 */
		isChrome() {
			return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
		}

		/**
		 * @returns {boolean}
		 */
		isFireFox() {
			return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		}
	}

	const browserDetect = new BrowserDetect();

	/**
	 * Add styles according browser type and OS.
	 * Customization for Chrome.
	 */
	class HideScroll {
		constructor() {
			/**
			 * @type {number} period of show time
			 * @private
			 */
			this.showMs = 1000

			/**
			 * @type {WeakMap<HTMLElement, number|null>} value - timer
			 * @private
			 */
			this._initedElements = new WeakMap();

			/**
			 * @type {WeakSet<MouseEvent|Event>}
			 * @private
			 */
			this._handledEvents = new WeakSet();
		}

		/**
		 * @returns {void}
		 */
		init() {
			this._initScrollIfNeed();
		}

		/**
		 * @param {string} className
		 * @param {HTMLElement} el
		 * @returns {void}
		 */
		_addClass(className, el) {
			el.classList.add(className);
		}

		/**
		 * @returns {void}
		 */
		_initScrollIfNeed() {
			this._initBrowserClasses();

			this._getScrollElements()
				.filter((el) => !this._initedElements.has(el))
				.forEach((el) => {

					this._initedElements.set(el, null);

					if (browserDetect.isMacLike() || browserDetect.isFireFox()) {
						return;
					}

					this._initScrollChromeEvent(el);
				});
		}

		_initBrowserClasses() {
			[...document.querySelectorAll(SCROLL_ALL_CLASSES_SELECTOR)].forEach((el) => {
				if (browserDetect.isMacLike()) {
					this._addClass(SCROLL_CLASS_MAC_LIKE, el);
					return;
				}

				if (browserDetect.isFireFox()) {
					this._addClass(SCROLL_CLASS_FIREFOX, el);
					return;
				}

				if (browserDetect.isChrome()) {
					this._addClass(SCROLL_CLASS_CHROME, el);
					return;
				}

				this._addClass(SCROLL_CLASS_OTHER, el);
			});
		}

		/**
		 * @param {HTMLElement} el
		 * @returns {void}
		 */
		_initScrollChromeEvent(el) {
			el.addEventListener('scroll', (e) => this._showScrollForElement(e, el));
			el.addEventListener('mouseover', (e) => this._showScrollForElement(e, el, false));
		}

		/**
		 * @param {Event} e
		 * @param {HTMLElement} el
		 * @returns {void}
		 */
		_showScrollForElement(e, el) {
			this._initScrollIfNeed();

			if (this._initedElements.has(e)) {
				return;
			} else {
				this._initedElements.set(e);
			}

			let timer = this._initedElements.get(el);
			(null !== timer) && clearTimeout(timer);
			timer = setTimeout(() => this._hideScrollForElement(el), this.showMs);
			this._initedElements.set(el, timer);

			el.classList.add(SHOW_SCROLL_CLASS);
		}

		/**
		 * @param {HTMLElement} el
		 * @returns {void}
		 */

		_hideScrollForElement(el) {
			const timer = this._initedElements.get(el);
			if (null !== timer) {
				clearTimeout(timer);
				this._initedElements.set(el, null)
			}

			el.classList.remove(SHOW_SCROLL_CLASS);
		}

		/**
		 * @returns {HTMLElement[]}
		 */
		_getScrollElements() {
			return [...document.querySelectorAll(SCROLL_ELEMENTS_SELECTOR)]
		}
	}

	const hideScroll = (window.hideScroll = window.hideScroll || new HideScroll());

	// On document ready
	if (document.readyState === 'loading') {
		document.addEventListener('DOMContentLoaded', hideScroll.init.bind(hideScroll));
	} else {
		hideScroll.init();
	}


	// Webpack Support
	if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
		module.exports = hideScroll;
	}

})()